import {
  ref,
  computed,
} from '@vue/composition-api'
import {
  isEmpty, cloneDeep, min, max, debounce, capitalize, groupBy,
} from 'lodash'
import isNaN from 'lodash/isNaN'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { getUserData } from '@/api/auth/utils'

import {
  formatCurrency,
  convertShortTrip,
  convertISODateTime,
  convertISODateTimeLangVN,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default function useClassBookingHandle() {
  const { toastSuccess, toastError, toastWarning } = useToast()
  const { router } = useRouter()
  const {
    FLIGHT_APP_STORE_MODULE_NAME,
    calculatePriceClassBooking,
    getSearchFlight,
  } = useBookingHandle()

  const classBookingOptions = ['G', 'A', 'P', 'E', 'T', 'R', 'N', 'Q', 'L', 'K', 'H', 'S', 'M', 'U', 'W', 'Z', 'B', 'Y', 'O', 'I', 'D', 'C', 'J']
  const indexBookingClassShowPrice = ref(-1)

  const refLoading = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getLoading`]))
  const dataTrips = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getResultSearchClassBooking`])
  const selectedTrips = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedTripClassBooking`]))
  const status = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusClassBooking`]))
  const bookingClassCode = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getBookingClassCode`]))
  const listSelectedClassId = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedIdClassBooking`]))
  const storeSearchFlight = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`]))
  const showTotalPriceADT = computed(() => store.getters['app-flight-v2/getShowTotalPriceADT'])
  const airlineSearch = computed(() => store.getters['app-flight-v2/getAirlineSearchClassBooking'])
  const isDisableFilterDeparture = computed(() => store.getters['app-flight-v2/getDisableFilterDepartureTimeClassBooking'])
  const getNextResultReference = computed(() => store.getters['app-flight-v2/getNextResultReference'])
  const resCalcPrice = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getDataCalcTripClassBooking`]))
  const dataAddMoreFlight = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getAddMoreFlightClassBooking`]))

  const isSelectedAll = computed(() => {
    if (isEmpty(selectedTrips.value)) return false
    const lengthItinerarySearch = ['OW'].includes(storeSearchFlight.value?.type) ? 1 : ['RT'].includes(storeSearchFlight.value?.type) ? 2 : storeSearchFlight.value?.flights?.length
    return selectedTrips.value.flat().every(s => Boolean(s)) && selectedTrips.value.length === lengthItinerarySearch
  })

  const getAirlineNameByCode = code => store.getters['globalConfig/getAirlineNameByCode'](code)
  const getUniqueBookingClassIds = val => cloneDeep(val).flat().flatMap(item => (item && item.bookingClass && item.bookingClass.uniqueBookingClassId ? [item.bookingClass.uniqueBookingClassId] : [])).filter(Boolean)

  // Change Class Selected
  function handleSelectClassBooking(segment, bookingClass) {
    const dataTrip = selectedTrips.value[segment.itineraryIndex]
    const equalTripIndex = dataTrip?.find(item => item)?.tripIndex === segment.tripIndex || undefined
    const { bookingClassAvail, ...rest } = segment
    const tripDraft = cloneDeep(selectedTrips.value)
    const statusDraft = cloneDeep(status.value)
    const bookingClassCodeDraft = cloneDeep(bookingClassCode.value)

    const updateDrafts = reset => {
      if (reset) {
        tripDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => null)]
        statusDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => 'NN')]
        bookingClassCodeDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => null)]
      }

      tripDraft[segment.itineraryIndex][segment.leg] = { ...rest, bookingClass }
      statusDraft[segment.itineraryIndex][segment.leg] = ['VN1A'].includes(airlineSearch.value)
        ? (['DS', 'LL', 'NN'].includes(bookingClass.status)
          ? bookingClass.status
          : !isNaN(Number(bookingClass.availability)) && Number(bookingClass.availability) !== 0 ? 'NN' : 'LL')
        : !isNaN(Number(bookingClass.availability)) && Number(bookingClass.availability) !== 0 ? 'NN' : 'LL'

      bookingClassCodeDraft[segment.itineraryIndex][segment.leg] = bookingClass?.code || null

      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSelectedTripClassBooking`, tripDraft)
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setStatusClassBooking`, statusDraft)
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setBookingClassCode`, bookingClassCodeDraft)
    }

    if (!isEmpty(dataTrip)) { // Có data
      if (equalTripIndex) { // Khi đã chọn segment tương ứng với index của segment trong cùng Trip và Itinerary
        updateDrafts(false)
      } else { // Có data và chọn sang hành trình khác trong cùng Itinerary
        updateDrafts(true)
      }
    } else { // No data
      updateDrafts(true)
    }

    indexBookingClassShowPrice.value = bookingClass.uniqueBookingClassId

    const dataSelectedClassId = getUniqueBookingClassIds(selectedTrips.value)
    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSelectedIdClassBooking`, dataSelectedClassId)
  }

  const segmentArr = computed(() => dataTrips.value?.map(t => t.trips.map(trip => trip.segments.map(seg => seg))).flat(2))

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // flightSearchType === 'RT' => tính giá all hành trình (Giá kết hợp)
  // flightSearchType === 'RT' và chọn tính giá rẻ nhất: đổi lại data bookingClass theo từng chặng như cũ, giá sẽ là giá kết hợp
  function handleChangeNewPrice(segment, classSelect) {
    try {
      const newSegment = segmentArr.value.find(
        seg => seg.itineraryIndex === segment.itineraryIndex
        && seg.tripIndex === segment.tripIndex
        && seg.uniqueSegmentId === segment.uniqueSegmentId,
      )
      const newBookingClass = newSegment.bookingClassAvail.find(b => b.code === classSelect.class)
      const setDataNewBookingClass = {
        code: classSelect.class,
        availability: newBookingClass.availability,
        prices: classSelect.prices,
        uniqueBookingClassId: newBookingClass.uniqueBookingClassId,
      }

      handleSelectClassBooking(newSegment, setDataNewBookingClass)
    } catch (error) {
      // toastError({
      //   title: 'Có lỗi xảy ra khi lưu giá hành trình, vui lòng kiểm tra lại thông tin hành trình đang thực hiện!',
      // })
      console.error('lỗi lưu giá handleChangeNewPrice: ', error)
    }
  }

  const dataPriceCalculated = ref(null)

  const createTripElement = trip => ({
    source: airlineSearch.value,
    airline: trip[0]?.airline,
    clientId: '',
    bookingKey: '',
    fareBreakdowns: [],
    segments: trip.map((seg, segIndex) => ({
      leg: seg.leg || segIndex,
      airline: seg.airline,
      departure: seg.departure,
      arrival: seg.arrival,
      departureTime: convertISODateTime(seg.departureDate, seg.departureTimezone).ISOdatetime,
      arrivalTime: convertISODateTime(seg.arrivalDate, seg.arrivalTimezone).ISOdatetime,
      flightNumber: seg.flightNumber ? String(seg.flightNumber) : '',
      fareType: '',
      fareBasisCode: '',
      bookingClass: seg?.bookingClass?.code || '',
      groupClass: seg?.bookingClass?.groupClass || '',
      marriageGrp: '',
      segmentValue: seg?.segmentValue || '',
      segmentId: '',
    })),
  })

  // Tính giá dựa trên calcMode: COMBINE, SINGLE
  const createPayload = (passengerTypeRequests, bargainFinder, itineraries) => ({
    contact: getUserData().employeeData.id,
    source: airlineSearch.value,
    agencyCode: getUserData().employeeData?.agency?.agencyCode,
    paxCode: '',
    tourCode: '',
    accountCode: '',
    bargainFinder,
    passengerTypeRequests,
    itineraries,
  })

  async function recalculatePrice(payload) {
    const {
      passengerTypeRequests, selectedTrips, bargainFinder, calcMode,
    } = payload
    const isCombine = ['COMBINE'].includes(calcMode)

    try {
      dataPriceCalculated.value = null
      let resultArray

      // ANCHOR - STEP 1: call api
      if (isCombine) {
        const tripEle = selectedTrips.map(trip => createTripElement(trip))
        const payload = createPayload(passengerTypeRequests, bargainFinder, tripEle)
        const res = await calculatePriceClassBooking(payload)
        const flights = selectedTrips.map(t => `${t[0].departure}-${t[t.length - 1].arrival}`).join(', ')

        resultArray = [{
          status: 'success',
          tripIndex: 0,
          dataTrip: selectedTrips,
          flights,
          res,
        }]
      } else {
        resultArray = await Promise.all(
          selectedTrips.map(async (trip, tripIndex) => {
            const flights = `${trip[0].departure}-${trip[trip.length - 1].arrival} ${convertISODateTime(trip[0].departureDate, trip[0].departureTimezone).dayAndMonth}`
            try {
              const tripEle = createTripElement(trip)
              const payload = createPayload(passengerTypeRequests, bargainFinder, [tripEle])
              const res = await calculatePriceClassBooking(payload)

              const result = {
                status: 'success',
                tripIndex,
                dataTrip: trip,
                flights,
                res,
              }
              return result
            } catch (error) {
              console.error(`Lỗi thực hiện tính giá hành trình #${tripIndex}:`, { error })
              return {
                status: 'error',
                tripIndex,
                dataTrip: trip,
                flights,
                res: error,
              }
            }
          }),
        )
      }

      // ANCHOR - STEP 2: save price + change selected to new booking class
      if (resultArray) {
        dataPriceCalculated.value = resultArray
      }

      const isAllDataSuccess = resultArray.every(trip => trip.status === 'success')
      if (bargainFinder) {
        if (isCombine) { // COMBINE
          const arrAllSegmentCalc = resultArray[0]?.dataTrip?.flatMap(trip => trip?.flatMap(segment => segment))
          const resBookingInfosData = resultArray[0]?.res[0]

          if (arrAllSegmentCalc && resBookingInfosData) {
            arrAllSegmentCalc.forEach((oldSegment, oldIndex) => {
              // && ((newS.departureDate === convertISODateTime(oldSegment.departureDate, oldSegment.departureTimezone).dateFilter) || (newS.departureDate === oldSegment.departureDate))
              // const newSegmentData = resBookingInfosData.bookingInfos.find(newS => newS.departure === oldSegment.departure
              //     && newS.arrival === oldSegment.arrival
              //     && newS.airline === oldSegment.airline
              //     && String(newS.flightNumber) === String(oldSegment.flightNumber)
              //     && (newS.departureDate.split('T')[0] === oldSegment.departureDate.split('T')[0])
              //     && newS.departureTimezone === oldSegment.departureTimezone)

              // ANCHOR - Sep confirm mỗi ngày chỉ có 1 chuyến bay có 1 flightNumber và airline / chặng
              let errorTrip = null

              const newSegmentData = resBookingInfosData.bookingInfos.find(newS => {
                const newTrip = `${newS.departure}${newS.arrival} ${newS.airline}${newS.flightNumber} ${newS.departureDate.split('T')[0]} ${newS.departureTimezone}`
                const oldTrip = `${oldSegment.departure}${oldSegment.arrival} ${oldSegment.airline}${oldSegment.flightNumber} ${oldSegment.departureDate.split('T')[0]} ${oldSegment.departureTimezone}`
                const result = oldTrip === newTrip
                if (!result) { errorTrip = { oldTrip, newTrip } }
                return result
              })

              if (!newSegmentData) {
                toastWarning({ title: 'messagesList.error', content: 'Lỗi tìm kiếm thông tin hành trình mới!' })
                console.error('Lỗi thông tin hành trình: ', {
                  oldData_All: arrAllSegmentCalc,
                  newData: resBookingInfosData,
                  segmentError: oldSegment,
                  errorTrip,
                })
                return
              }

              if (['VN1A'].includes(airlineSearch.value) && oldSegment.isExtraTrip) {
                const newSegmentModify = {
                  ...oldSegment,
                  bookingClass: {
                    ...oldSegment.bookingClass,
                    code: newSegmentData.bookingClass,
                  },
                }

                const extraTrips = cloneDeep(dataAddMoreFlight.value)

                for (const trip of extraTrips) {
                  if (trip.segments.some(s => s.segmentUuid === newSegmentModify.segmentUuid)) {
                    const newData = {
                      ...trip,
                      segments: trip.segments.map(segment => {
                        if (segment.segmentUuid === newSegmentModify.segmentUuid) {
                          return newSegmentModify
                        }
                        return segment
                      }),
                    }
                    extraTrips.splice(extraTrips.findIndex(t => t.tripUuid === newData.tripUuid), 1, newData)
                  }
                }

                store.dispatch('app-flight-v2/setAddMoreFlightClassBooking', extraTrips)
              } else {
                const classSelect = {
                  class: newSegmentData.bookingClass,
                  prices: oldSegment.bookingClass?.prices?.map(price => ({
                    fareBasisCode: resBookingInfosData[oldIndex]?.fareInfos[0]?.fareBasisCode || price?.fareBasisCode,
                    net: price.net,
                    tax: price.tax,
                    final_tax: price.final_tax,
                    fare: price.fare,
                    paxType: price.paxType,
                  })),
                }

                handleChangeNewPrice(oldSegment, classSelect)
              }
            })
          }
        } else { // SINGLE
          resultArray.forEach(trip => {
            if (['error'].includes(trip.status)) return

            trip.dataTrip.forEach(segment => {
              const classSelect = {
                class: trip.res[0].bookingInfos[segment.leg] && trip.res[0].bookingInfos[segment.leg].bookingClass
                  ? trip.res[0].bookingInfos[segment.leg].bookingClass
                  : trip.res[0].bookingInfos[0].bookingClass,
                prices: trip.res.map(price => ({
                  fareBasisCode: price.fareInfos[segment.leg] && price.fareInfos[segment.leg].fareBasisCode
                    ? price.fareInfos[segment.leg].fareBasisCode
                    : price.fareInfos[0].fareBasisCode,
                  net: price.basePrice,
                  tax: price.totalTaxes,
                  final_tax: price.finalTotalTaxes,
                  fare: price.finalTotalPrices,
                  paxType: price.passengerRequested.paxType,
                })),
              }
              handleChangeNewPrice(segment, classSelect)
            })
          })
        }
      }

      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDataCalcTripClassBooking`, resultArray)
      return isAllDataSuccess
    } catch (error) {
      console.error('Error recalculatePrice:', error)
      return false
    }
  }

  const getTotalAllTripPriceCalc = resCalcTrip => { // Lấy giá tổng của tất cả hành trình đã tính giá
    const total = resCalcTrip.reduce((accumulator, trip) => {
      const tripTotal = trip.res.reduce((tripTotal, item) => ({
        net: tripTotal.net + item.basePrice * (item?.passengerRequested?.quantity || 1),
        tax: tripTotal.tax + item.finalTotalTaxes * (item?.passengerRequested?.quantity || 1),
        total: tripTotal.total + item.finalTotalPrices * (item?.passengerRequested?.quantity || 1),
      }), { net: 0, tax: 0, total: 0 })
      accumulator.net += tripTotal.net
      accumulator.tax += tripTotal.tax
      accumulator.total += tripTotal.total
      return accumulator
    }, { net: 0, tax: 0, total: 0 })

    const prices = [
      {
        net: formatCurrency(total.net),
        tax: formatCurrency(total.tax),
        total: formatCurrency(total.total),
      },
    ]

    return prices
  }

  // Lấy giá tạm tính (Draft)
  const calculatePriceByPaxType = (trip, paxType) => {
    const fareCode = trip.map(seg => seg.bookingClass.prices.find(price => paxType === price.paxType)?.fareBasisCode || '')
    const net = trip.reduce((total, seg) => total + (seg.bookingClass.prices.find(price => paxType === price.paxType)?.net || 0), 0)
    const tax = trip.reduce((total, seg) => total + (seg.bookingClass.prices.find(price => paxType === price.paxType)?.final_tax || 0), 0)
    const totalFare = trip.reduce((total, seg) => total + (seg.bookingClass.prices.find(price => paxType === price.paxType)?.fare || 0), 0)

    return {
      paxType,
      fareBasisCode: fareCode,
      net: formatCurrency(net),
      tax: formatCurrency(tax),
      total: formatCurrency(totalFare),
    }
  }

  const paxTypes = ['ADULT', 'CHILD', 'INFANT']

  const getDraftPriceTripSelected = trip => paxTypes.filter(p => storeSearchFlight.value[p.toLowerCase()]).map(paxType => calculatePriceByPaxType(trip, paxType))

  const convertData = inputData => {
    const result = {}
    inputData.forEach(item => {
      const { paxType, total, fareBasisCode } = item
      if (!result[paxType]) {
        result[paxType] = {
          paxType,
          fareBasisCodes: [...fareBasisCode],
          total: parseInt(total.replace(/,/g, ''), 10),
        }
      } else {
        fareBasisCode.forEach(code => {
          if (!result[paxType].fareBasisCodes.includes(code)) {
            result[paxType].fareBasisCodes.push(code)
          }
        })
        result[paxType].total += parseInt(total.replace(/,/g, ''), 10)
      }
    })
    const outputData = Object.values(result)
    return outputData
  }

  function calculateTotalFareVU(paxNum, data) {
    let totalAllTypes = 0
    const totalFare = {}
    data.forEach(item => {
      const paxType = item.paxType
      const quantity = paxNum[paxType]
      const total = Number(item.total.replace(/,/g, '')) * quantity
      totalFare[paxType] = total
      totalAllTypes += total
    })
    // totalFare.totalAllTypes = formatCurrency(totalAllTypes)
    return formatCurrency(totalAllTypes)
  }

  function getTotalPriceTripSelectVU(trips) {
    const a = trips
      .map(trip => paxTypes.filter(p => storeSearchFlight.value[p.toLowerCase()])
        .map(paxType => calculatePriceByPaxType(trip, paxType))).flat()

    const paxNum = {
      ADULT: storeSearchFlight.value.adult,
      ...(storeSearchFlight.value.child !== 0 && { CHILD: storeSearchFlight.value.child }),
      ...(storeSearchFlight.value.infant !== 0 && { INFANT: storeSearchFlight.value.infant }),
    }

    const totalPriceOneTrip = convertData(a)
    const totalPriceAllPax = calculateTotalFareVU(paxNum, a)

    return {
      totalPriceOneTrip,
      totalPriceAllPax,
    }
  }

  // =================================================================

  const isSelectAllTrip = (trips, searchArray) => {
    const checkTrip = trips.length === searchArray.length
    if (!checkTrip) {
      toastError({ title: 'messagesList.error', content: 'Vui lòng chọn tất cả hành trình!' })
      return false
    }
    const checkSegment = trips.every(t => t.every(Boolean))
    if (!checkSegment) {
      toastError({ title: 'messagesList.error', content: 'Vui lòng chọn tất cả hành trình!' })
      return false
    }
    return true
  }

  function resolveTooltipVariant(bookingClass) {
    return bookingClass.availability === 0 ? 'secondary' : 'info'
  }

  function getSortTripBySegment(segment, isSelect = false) {
    const {
      departure, arrival, departureDate, departureTimezone, arrivalDate, arrivalTimezone, airline, flightNumber, airCraft,
    } = segment

    const getDepartureTime = departureDate && departureTimezone ? convertISODateTime(departureDate, departureTimezone).time : ''
    const getArrivalTime = arrivalDate && arrivalTimezone ? convertISODateTime(arrivalDate, arrivalTimezone).time : ''
    const getDepartureDayAndMonth = departureDate && departureTimezone ? convertISODateTime(departureDate, departureTimezone).dayAndMonth : ''
    const getAirline = airline || ''
    const getFlightNumber = flightNumber || ''
    const getDeparture = departure || ''
    const getArrival = arrival || ''
    const getAirCraft = airCraft || ''

    return isSelect // dùng với key value-input: trong div chưa tăng được space
      ? `${convertISODateTime(segment.departureDate, segment.departureTimezone).time}-${convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time}  ${convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth}  ${segment.airline}${segment.flightNumber}  ${segment.bookingClass.code}  ${segment.departure}${segment.arrival}`
      : `${getDepartureTime}-${getArrivalTime}   ${getDepartureDayAndMonth}   ${getAirline}${getFlightNumber}   ${getDeparture}${getArrival}  |  ${getAirCraft}`
  }

  // const dataTripsToCalcPrice = ref(null)

  async function handleOpenCalculatePriceModal(modalId) {
    if (getSearchFlight.value && getSearchFlight.value.airline === 'VU') {
      toastError({ title: 'Không hỗ trợ tính giá với Viettravel Airlines!' })
      return
    }

    const isSelectAll = isSelectAllTrip(selectedTrips.value, dataTrips.value)

    if (isSelectAll) {
      // dataTripsToCalcPrice.value = null
      // await new Promise(resolve => {
      //   dataTripsToCalcPrice.value = selectedTrips.value.map((trip, indexTrip) => trip.map((segment, indexSegment) => ({
      //     ...segment,
      //     status: status.value[indexTrip][indexSegment] || status.value[indexTrip],
      //     // isDomestic: isDomesticFlight(segment.startPoint, segment.endPoint),
      //   })))
      //   resolve()
      // })

      await delay(200)

      this.$bvModal.show(`modal-result-recalculate-price-class-booking-${modalId}`)
    }
  }

  // Check tất cả các chuyến đều là nội địa, nếu đúng mới hiện nút tiếp tục
  const isEveryDomesticFlights = computed(() => {
    if (!dataTrips.value) return false
    return dataTrips.value.every(flight => flight?.isDomestic)
  })

  const countPaxs = computed(() => {
    const { adult = 0, child = 0, infant = 0 } = storeSearchFlight.value || {}
    const paxs = [
      { title: 'Người lớn', code: 'ADULT', count: adult },
      { title: 'Trẻ em', code: 'CHILD', count: child },
      { title: 'Em bé', code: 'INFANT', count: infant },
    ]
    return paxs.filter(pax => pax.count > 0)
  })

  return {
    store,
    router,
    min,
    max,
    delay,
    isEmpty,
    cloneDeep,
    groupBy,
    debounce,
    capitalize,
    formatCurrency,
    convertShortTrip,
    convertISODateTime,
    convertISODateTimeLangVN,
    toastSuccess,
    toastError,
    toastWarning,
    useBookingHandle,
    getSortTripBySegment,
    getNextResultReference,

    getUniqueBookingClassIds,
    resolveTooltipVariant,
    getAirlineNameByCode,
    recalculatePrice,
    // getTotalPriceOneTripCalc,
    getTotalAllTripPriceCalc,
    getDraftPriceTripSelected,
    resCalcPrice,
    isSelectAllTrip,
    handleSelectClassBooking,

    getTotalPriceTripSelectVU,

    // dataTripsToCalcPrice,
    handleOpenCalculatePriceModal,

    // NOTE: all data is reactive...........maybe...............hmmm........      :/
    refLoading,
    status,
    dataTrips,
    selectedTrips,
    listSelectedClassId,
    isEveryDomesticFlights,
    indexBookingClassShowPrice,
    classBookingOptions,
    showTotalPriceADT,
    airlineSearch,
    isSelectedAll,
    storeSearchFlight,
    isDisableFilterDeparture,
    dataAddMoreFlight,
    countPaxs,
  }
}
